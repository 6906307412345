


















import ZoneCondition from '@/includes/logic/Modules/models/conditions/ZoneCondition/ZoneCondition'
import { InputSetups } from '@/mixins/input-setups'
import { ZoneTypeEnum } from '@/includes/logic/Modules/types/types'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, VModel } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component({})
export default class ZoneConditionView extends Mixins<UseFields>(UseFields, InputSetups) {
  @VModel() model!: ZoneCondition

  get zoneTypeOptions() {
    return Object.values(ZoneTypeEnum)
      .map((v) => ({
        label: this.$t(`zone_type_${ snakeCase(v.toString()) }_option`).toString(),
        value: v
      }))
  }
}
